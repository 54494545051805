import type { ComponentType } from "react";
import React from "react";
import type { FlightplanId, EdstFpData } from "@poscon/shared-types/eram";
import { font2Dimension } from "~/utils/constants";
import type { ColorSource } from "pixi.js";

export const ListSeparator = ({
  x = 0,
  y = 0,
  width,
  thickness = 2,
  color = 0xe3cfb4,
}: {
  x?: number;
  y?: number;
  width: number;
  thickness?: number;
  color?: ColorSource;
}) => (
  <graphics
    x={x}
    y={y}
    draw={(g) => {
      g.clear();
      g.moveTo(0, 0).lineTo(width, 0).stroke({ width: thickness, color });
    }}
  />
);

type MapRowProps = {
  y0?: number;
  list: string[];
  width: number;
  edstFlightplans: Record<FlightplanId, EdstFpData>;
  Component: ComponentType<{ fpId: FlightplanId }>;
};
export const ListMapper = ({ y0 = 0, list, Component, width, edstFlightplans }: MapRowProps) => {
  const yList = list.reduce(
    (acc, fpId, i) => {
      let newY = acc.at(-1)!;
      newY += font2Dimension.height + 6;
      if (edstFlightplans[fpId]?.showFreeText) {
        newY += font2Dimension.height + 6;
      }
      if (i % 3 === 2) {
        newY += 2;
      }
      acc.push(newY);
      return acc;
    },
    [0],
  );

  return (
    <container y={y0}>
      {list.slice(0, 30).map((fpId, i) => {
        const showSep = i % 3 === 2;
        return (
          <container key={fpId} y={yList[i]}>
            <Component key={fpId} fpId={fpId} />
            {showSep && (
              <ListSeparator
                y={(font2Dimension.height + 5) * (edstFlightplans[fpId]?.showFreeText ? 2 : 1)}
                width={width}
                thickness={1}
                color={0x252525}
              />
            )}
          </container>
        );
      })}
    </container>
  );
};
